export enum IconType {
  'add-circle' = 'add-circle',
  'add-square' = 'add-square',
  'arrow-down' = 'arrow-down',
  'arrow-left' = 'arrow-left',
  'arrow-right' = 'arrow-right',
  'arrow-up' = 'arrow-up',
  'assess' = 'assess',
  'calendar-plus' = 'calendar-plus',
  'call-chat' = 'call-chat',
  'check' = 'check',
  'clock-circle' = 'clock-circle',
  'close-alt' = 'close-alt',
  'close-circle' = 'close-circle',
  'close-square' = 'close-square',
  'cloud-upload' = 'cloud-upload',
  'code' = 'code',
  'danger-triangle' = 'danger-triangle',
  'dropdown' = 'dropdown',
  'dropdown-bold' = 'dropdown-bold',
  'download-file' = 'download-file',
  'eye-closed' = 'eye-closed',
  'feed' = 'feed',
  'file' = 'file',
  'fine-tunning' = 'fine-tunning',
  'flag' = 'flag',
  'flip-vertical' = 'flip-vertical',
  'graph-up' = 'graph-up',
  'hand-stars' = 'hand-stars',
  'incognito' = 'incognito',
  'info-square' = 'info-square',
  'maximize-square' = 'maximize-square',
  'minimize-square' = 'minimize-square',
  'more' = 'more',
  'notes' = 'notes',
  'pie-chart' = 'pie-chart',
  'radio-translation' = 'radio-translation',
  'restart' = 'restart',
  'restart-circle' = 'restart-circle',
  'search' = 'search',
  'square-forward' = 'square-forward',
  'stars' = 'stars',
  'sun-glow' = 'sun-glow',
  'three-dots-horizontal' = 'three-dots-horizontal',
  'transfer-horizontal' = 'transfer-horizontal',
  'trash-bin-minimalistic' = 'trash-bin-minimalistic',
  'trash-bin-outlined' = 'trash-bin-outlined',
  'user-check' = 'user-check',
  'user-circle' = 'user-circle',
  'user-id' = 'user-id',
  'user-speak' = 'user-speak',
  'users-group-rounded' = 'users-group-rounded',
}
